export const challenges = {
  getChallenges: {
    url: "/getChallenges",
    method: "GET",
    params: null,
  },
  getChallengesById: {
    url: "/getChallengesById/",
    method: "GET",
    params: null,
    id: null,
  },
  updateChallengesById: {
    url: "/updateChallenge/",
    method: "POST",
    id:null,
    data: null,
  },
  updateChallenges: {
    url: "/updateChallenge",
    method: "POST",
    id:null,
    data: null,
  },
  deleteChallenges: {
    url: "/deleteChallenges/",
    method: "DELETE",
    id:null
  },
};
