<template>
  <Layout>
    <h3>Edit Challenge</h3>
    <div v-if="loading" style="text-align: center; margin: 28px">
      <pulse-loader color="#e83e8c" />
    </div>
    <div class="card" v-else>
      <div class="card-body m-auto w-50">
        <div class="mb-4">
          <img
            class="w-10 rounded"
            width="400"
            :src="this.form.image"
            alt="Cover image"
          />
        </div>
        <FormulateForm @submit="submitForm" class="mb-4">
          <FormulateInput
            v-model="form.name"
            validation="required"
            name="name"
            type="text"
            label="Challenge Name"
          />
          <FormulateInput
            v-model="form.name_ar"
            validation="required"
            name="name_ar"
            type="text"
            label="Challenge Name(AR)"
          />

          <FormulateInput
            v-model="form.description"
            name="description"
            type="text"
            label="Challenge Description"
          />
          <FormulateInput
            v-model="form.description_ar"
            name="description_ar"
            type="text"
            label="Challenge Description(AR)"
          />
          <FormulateInput
            v-model="form.value"
            name="value"
            type="text"
            label="Value"
          />
          <!-- <FormulateInput
            v-model="form.value_ar"
            name="value_ar"
            type="text"
            label="Value(AR)"
          /> -->
          <FormulateInput
            v-model="form.sort_value"
            name="sort_value"
            validation="required"
            type="number"
            label="Sort Value"
          />
          <FormulateInput
            type="image"
            label="Image"
            name="image"
            v-model="images"
          />
          <b-button variant="primary" type="submit" class="m-auto"
            >Submit</b-button
          >
        </FormulateForm>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { challenges } from "@/config/api/challenges";

export default {
  components: { Layout },

  data() {
    return {
      loading: false,
      details: null,
      form: {
        name: "",
        value: "",
        name_ar: "",
        description_ar: "",
        description: "",
        // value_ar: "",
        sort_value: "",
        image: "",
      },
      images: null,
    };
  },

  methods: {
    async submitForm(data) {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("value", data.value);
      formData.append("name_ar", data.name_ar);
      formData.append("description", data.description);
      formData.append("description_ar", data.description_ar);

      // formData.append("value_ar", data.value_ar);
      formData.append("sort_value", data.sort_value);
      if (this.images) {
        formData.append("file", this.images?.files[0]?.file);
      }
      let api = challenges.updateChallengesById;
      api.id = this.$route.params.id;
      api.data = formData;
      this.loading = true;
      await this.fileUploadAPI(api)
        .then((res) => {
          this.form = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          window.location.reload();
        });
    },
  },

  mounted() {
    const api = challenges.getChallengesById;
    api.id = this.$route.params.id;
    this.loading = true;
    this.generateAPI(api)
      .then((res) => {
        this.form = res.data;
        console.log(this.form, "====---value");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style>
.images-min-width {
  width: 180px;
}

.preview-container {
  background-color: #fafafa;
  border-radius: 12px;
  padding: 18px 12px;
}

.tribe-form .formulate-input[data-classification="group"] [data-is-repeatable] {
  border: unset !important;
  box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(209 209 209 / 75%);
}
</style>
